import {getList, save, update, remove, getInfoList, modifyLapScore} from "@/api/exp/ScoreApi";
import {CommonModel} from "@/model/CommonModel";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

/**
 成绩model
 */
class ScoreModel {
    // 获取列表
    static async getList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getList(params)
        if (size === 0) {  // 不分页
            return data.data;
        } else { // 分页
            let list = CommonModel.generateListMybatisPlus(data.data)
            // 遍历config转换为对象
            let listData = list[0]
            return [listData, list[1]];
        }
    }

    // 新增
    static async save(param) {
        let [res] = await save(param)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 修改
    static async update(param) {
        let [res] = await update(param)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 新增
    static async remove(ids) {
        let [res] = await remove(ids)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 获取成绩详情列表
    static async getInfoList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getInfoList(params)
        if (size === 0) {  // 不分页
            return data.data;
        } else { // 分页
            let list = CommonModel.generateListMybatisPlus(data.data)
            // 遍历config转换为对象
            let listData = list[0]
            return [listData, list[1]];
        }
    }

    // 修改-实验分数
    static async modifyLapScore(param) {
        let [res] = await modifyLapScore(param)
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 导出某个成绩excel
    static export(scheduleid, search, labreportstatus, administrationClazzId) {
        if (!search) {
            search = "null"
        }
        if (!labreportstatus) {
            labreportstatus = "null"
        }
        if (!administrationClazzId) {
            administrationClazzId = "null"
        }
        let url = API_URL_EXP_CONSUMER + `consumer/performanceManagement/export/Score/information/${scheduleid}/${search}/${labreportstatus}/${administrationClazzId}`
        console.log(url)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "学生成绩信息列表.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    // 导出所有成绩excel
    static exportMultiple(teacherId,semesterId,clazzId,experimentId,experimentStatus) {
        if (!semesterId) {
            semesterId = "null"
        }
        if (!clazzId) {
            clazzId = "null"
        }
        if (!experimentId) {
            experimentId = "null"
        }
        if (!experimentStatus) {
            experimentStatus = "null"
        }
        let url = API_URL_EXP_CONSUMER + `consumer/performanceManagement/exportMultiple/${teacherId}/${semesterId}/${clazzId}/${experimentId}/${experimentStatus}`
        console.log(url)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "学生成绩信息列表.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    // 导出全校所有学生成绩excel
    static exportOneSchoolAllStudentScore(schoolId,experimentId,semesterId) {
        let url = API_URL_EXP_CONSUMER + `consumer/performanceManagement/export/Score/oneSchoolAllStudentExperimentResult/${schoolId}/${experimentId}/${semesterId}`
        console.log(url)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "学生成绩信息列表.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}

export {ScoreModel}
