<template>
  <div class="page-container">
    <!--年级和班级名称-->
    <div class="page-title flex flex-between">
      <div class="left">
        <span class="dot"></span>
        <span class="title">{{ title }}</span>
      </div>
      <div class="right">
        <el-button type="primary" size="small" @click="ListMethods().clickExportBtn()">导出成绩</el-button>
      </div>
    </div>
    <!--筛选-->
    <div class="filter-container clearFix">
      <list-search-filter :search-filter="lists.searchFilter"
                          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)">
        <!--  操作  -->
        <div slot="right-container">
        </div>
      </list-search-filter>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column label="账号" prop="studentaccount" align="center" :sortable="'custom'">
          <template slot-scope="scope">
            <span>{{ scope.row.studentaccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.studentname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="行政班级" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.gradeName }} - {{ scope.row.administrationClazzName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="experimentscore" :sortable="'custom'" width="200">
          <template slot="header" slot-scope="scope">
           <span>实验分数
              <el-tooltip class="item" effect="dark" content="若多次实验，显示最高分" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
           </span>
          </template>
          <template slot-scope="scope">
            <div class="experiment-score">
              <span v-if="scope.row.isfinishexp">{{ scope.row.experimentscore | numberFormat }}</span>
              <el-dropdown v-if="scope.row.studentScoreEntities.length>0">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item disabled v-for="(item,index) in scope.row.studentScoreEntities" :key="index">
                    <span :class="item.score==scope.row.experimentscore?'light':''">{{
                        item.updatetime | dateFormat
                      }}的实验分数 {{ item.score | numberFormat }}分</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span v-if="!scope.row.isfinishexp">--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="labreportscore" :sortable="'custom'" width="200">
          <template slot="header" slot-scope="scope">
           <span>实验报告
              <el-tooltip class="item" effect="dark" content="" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
           </span>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.labreportstatus">{{ scope.row.labreportscore | numberFormat }}</span>
            <span v-if="!scope.row.labreportstatus&&scope.row.labreportcontent">待批改</span>
            <span v-if="!scope.row.labreportcontent">未提交</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="totalscore" :sortable="'custom'" width="200">
          <template slot="header" slot-scope="scope">
           <span>综合分数
              <el-tooltip class="item" effect="dark" content="综合分数=实验分*0.4+实验报告分*0.6" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
           </span>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.isfinishexp">{{ scope.row.totalscore | numberFormat }}</span>
            <span v-if="!scope.row.isfinishexp">--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round v-if="scope.row.labreportcontent"
                       @click="e=>ListMethods().clickViewBtn(scope.row,scope.$index)">批改实验报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--详情弹窗-->
    <el-dialog
        :close-on-click-modal="false"
        :title="entityInfo.title"
        :visible.sync="entityInfo.dialog"
        width="1000px"
        center
        v-el-drag-dialog>
      <div class="dialog-container report-container">
        <div class="header flex flex-between">
          <div class="li">
            <span class="title">姓名：</span>
            <span class="content">{{ entityInfo.edit.studentname }}</span>
          </div>
          <div class="li">
            <span class="title">账号：</span>
            <span class="content">{{ entityInfo.edit.studentaccount }}</span>
          </div>
          <div class="li">
            <span class="title">班级：</span>
            <span class="content">{{ clazzName }}</span>
          </div>
          <div class="li">
            <span class="title">报告提交时间：</span>
            <span class="content">{{ entityInfo.edit.labreportTime | dateFormat }}</span>
          </div>
        </div>
        <div class="text-container html-view limit-height" v-html="entityInfo.edit.labreportcontent"></div>
        <div class="footer flex flex-between">
          <div class="left flex flex-start">
            <span class="title">分数(0到100)：</span>
            <el-input style="width: 200px" type="number" v-model.number="entityInfo.edit.labreportscore"></el-input>
          </div>
          <div class="right flex flex-end">
            <div style="color: #999">
              批阅老师:<span v-if="entityInfo.edit.labreportcheckman">
              {{ entityInfo.edit.labreportcheckman }}{{
                " "
              }}{{ entityInfo.edit.labreportchecktime | dateFormat }}
              </span>
              <span v-else>
                {{ userInfo.username }}{{
                  " "
                }}{{ new Date() | dateFormat }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex flex-around">
          <div>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickReportPrevBtn()">上一个报告
            </el-button>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickNoReportPrevBtn()">上一个未批改的报告
            </el-button>
          </div>
          <div>
            <el-button type="default" size="small"
                       @click="entityInfo.dialog=false">取 消
            </el-button>
            <el-button type="primary" size="small"
                       @click="EntityInfoMethods().clickSureBtn()">确认批改
            </el-button>
          </div>
          <div>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickNoReportNextBtn()">下一个未批改的报告
            </el-button>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickReportNextBtn()">下一个报告
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--导入input-->
    <input
        id="importFile"
        type="file"
        style="display: none"
        @change="(files)=>{ListMethods().importFileChange(files)}"
    >
  </div>
</template>

<script>
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {date_format, find_obj_from_arr_by_id, objectToLVArr} from '@/utils/common'
import {mapState} from 'vuex'
import {StudentModel} from "@/model/exp/StudentModel";
import {dateFormat, numberFormat} from "@/filter/filter";
import {ScoreModel} from "@/model/exp/ScoreModel";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";

export default {
  name: 'teacherScoreInfo',
  components: {ListSearchFilter},
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat: dateFormat,
    numberFormat: numberFormat
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      clazzId: this.$route.query["clazzid"],
      clazzName: this.$route.query["clazzname"],
      experimentId: this.$route.query["experimentid"],
      experimentName: this.$route.query["experimentname"],
      semesterId: this.$route.query["semesterid"],
      scheduleId: this.$route.query["scheduleId"],// 教师实验安排id
      title: this.$route.query["title"],
      // 外部方法
      date_format: date_format,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {
          labreportstatus: ""
        },
        queryBase: {},
        pages: {
          size: 20
        },
        searchFilter: {
          search: [
            {
              type: 'input',
              label: '请输入学生姓名、账号',
              key: 'search',
              value: ''
            }
          ],
          filter: [
            {
              type: 'select',
              label: '行政班',
              key: 'administrationClazzId',
              hideTitle: true,
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验报告',
              key: 'labreportstatus',
              hideTitle: true,
              value: '',
              data: [
                {label: "全部", value: ""},
                {label: "未批改", value: 'false'},
                {label: "已批改", value: 'true'}
              ],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        dialog: false,
        scoreChange: false,// 分数是否改变
        edit: {
          labreportscore: ""
        },
        // 输入检测
        formRules: {
          'labreportscore': {required: true}
        },
      }
    }
  },
  async mounted() {
    // 设置基础查询
    if (this.clazzId) {
      this.lists.queryBase = {
        clazzid: this.clazzId,
        experimentid: this.experimentId,
        semesterid: this.semesterId,
        scheduleid: this.scheduleId,
      }
      this.ListMethods().getAdministrationClazzFilterList();
    }
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 生成行政班筛选列表
        async getAdministrationClazzFilterList() {
          let clazzInfo = await ClazzModel.getOne($this.clazzId)
          let options = [];// [{label:"","value":{}]
          let optionsObject = {};// {"value":"label"}
          let administrationClazzIdsArr = clazzInfo.administrationClazzIds.split("~")
          let administrationClazzNamesArr = clazzInfo.administrationClazzNames.split("~")
          // 新增全部
          options.push({
            label: "全部",
            value: ""
          })
          optionsObject[""] = "全部";
          administrationClazzIdsArr.forEach((li, index) => {
            options.push({
              label: administrationClazzNamesArr[index],
              value: li
            })
            optionsObject[li] = administrationClazzNamesArr[index];
          })
          $this.$set($this.lists.searchFilter.filter[0], "data", options)
          $this.$set($this.lists.searchFilter.filter[0], "dataObject", optionsObject)
        },
        // 获取列表
        async getList(page, size, query) {
          console.log(query)
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          if (!query.sort) {
            query.sort = "studentaccount,asc"
          }
          [$this.lists.list, $this.lists.pages] = await ScoreModel.getInfoList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          await this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          let querySort = $this.lists.query.sort
          querySort = CommonModel.elementTableSort(sort)
          $this.$set($this.lists.query, "sort", querySort)
          this.getList(1, $this.lists.pages.size, $this.lists.query)
        },
        // 初始化筛选列表
        async initFilter(type) {
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.lists.pages.size, query)
          $this.lists.query = query
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (await msg_confirm("确认要删除该学生吗？")) {
            if (await StudentModel.remove([entity.id], $this.clazzId)) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 点击详情按钮
        clickViewBtn(entity, $index) {
          if (entity.labreportcontent) {
            $this.entityInfo.title = $this.experimentName + "实验报告"
            $this.entityInfo.edit = JSON.parse(JSON.stringify(entity))
            $this.entityInfo.edit.labreportscoreOld = $this.entityInfo.edit.labreportscore // 记录旧数值，判断是否改变
            $this.entityInfo.dialog = true;
            $this.entityInfo.$index = $index;
          } else {
            msg_err("该学生暂未提交实验报告")
          }
        },
        // 点击导出按钮
        async clickExportBtn() {
          let search = $this.lists.query.search
          let labreportstatus = $this.lists.query.labreportstatus
          let administrationClazzId = $this.lists.query.administrationClazzId
          ScoreModel.export($this.scheduleId, search, labreportstatus, administrationClazzId)
        },
        // 实验报告状态改变
        async labStatusChange(v) {
          $this.lists.query.labreportstatus = !v
          this.getList(1, $this.lists.pages.size, $this.lists.query)
        },
      }
    },
    // 实体Methods
    EntityInfoMethods() {
      let $this = this;
      return {
        // 点击确定按钮
        async clickSureBtn() {
          let value = $this.entityInfo.edit.labreportscore
          let validate = true
          if (value < 0) {
            msg_err('请输入0-100内正整数作为分数')
            validate = false
          }
          if (value === "") {
            msg_err('请输入0-100内正整数作为分数')
            validate = false
          }
          if (value > 100) {
            msg_err('请输入0-100内正整数作为分数')
            validate = false
          }
          // 判断是否改变
          if (value === $this.entityInfo.edit.labreportscoreOld && value !== 0) {
            msg_err('分数未做修改！')
            return
          }
          if (validate) {
            let result = await ScoreModel.modifyLapScore({
              id: $this.entityInfo.edit.id,
              labreportscore: value,
            })
            if (result) {
              msg_success("修改实验分数成功")
              // 修改列表栏里的相关分数
              $this.$set($this.lists.list[$this.entityInfo.$index], "labreportscore", result.labreportscore)
              $this.$set($this.lists.list[$this.entityInfo.$index], "labreportstatus", true)
              $this.$set($this.lists.list[$this.entityInfo.$index], "totalscore", result.totalscore)
            }
          }
        },
        // 点击上一个未批改报告按钮
        async clickNoReportPrevBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex - 1; i >= 0; i--) {
            let li = list[i]
            if (!li.labreportstatus && li.labreportcontent) {// 找到上一个未批改的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {
            // 翻页
            if ($this.lists.pages.number > 1) {
              await $this.ListMethods().pageChange($this.lists.pages.number - 1)
              $this.entityInfo.$index = $this.lists.pages.size
              this.clickNoReportPrevBtn()
            } else {
              msg_err("没有上一个未批改的报告了！")
            }
          }
        },
        // 点击上一个报告按钮
        async clickReportPrevBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex - 1; i >= 0; i--) {
            let li = list[i]
            if (li.labreportcontent) {// 找到上一个的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {
            // 翻页
            if ($this.lists.pages.number > 1) {
              await $this.ListMethods().pageChange($this.lists.pages.number - 1)
              $this.entityInfo.$index = $this.lists.pages.size
              this.clickReportPrevBtn()
            } else {
              msg_err("没有上一个报告了！")
            }
          }
        },
        // 点击下一个未批改报告按钮
        async clickNoReportNextBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex + 1; i < list.length; i++) {
            let li = list[i]
            if (!li.labreportstatus && li.labreportcontent) {// 找到下一个未批改的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {// 未找到下一个
            // 翻页
            if ($this.lists.pages.number < $this.lists.pages.totalPage) {
              await $this.ListMethods().pageChange($this.lists.pages.number + 1)
              $this.entityInfo.$index = -1;
              this.clickNoReportNextBtn()
            } else {
              msg_err("没有下一个未批改的报告了！")
            }
          }
        },
        // 点击下一个报告按钮
        async clickReportNextBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex + 1; i < list.length; i++) {
            let li = list[i]
            if (li.labreportcontent) {// 找到下一个的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {// 未找到下一个
            // 翻页
            if ($this.lists.pages.number < $this.lists.pages.totalPage) {
              await $this.ListMethods().pageChange($this.lists.pages.number + 1)
              $this.entityInfo.$index = -1;
              this.clickReportNextBtn()
            } else {
              msg_err("没有下一个报告了！")
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.page-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 10px;

  .left {
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #2d7bc9;
      margin-right: 10px;
      display: inline-block;
    }

    .title {
      font-size: 15px;
      color: #666;
    }
  }

  .right {

  }
}


.text-container {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: #F5F5F5;
}

.report-container {
  .footer {

  }
}
</style>
