import {request_async} from "@/utils/requestAsync";
import { API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取列表
export async function getList(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/performanceManagement/list`, "get", params);
}

// 新增或修改
export async function save(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/performanceManagement/save`, "post_json", params);
}

// 修改
export async function update(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/performanceManagement/update`, "post_json", params);
}

// 删除
export async function remove(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/performanceManagement/delete`, "post_json", params);
}

// 获取成绩详情列表
export async function getInfoList(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/performanceManagement/info`, "get", params);
}

// 修改
/**
 *
 * @param params {{id,labreportscore}}
 */
export async function modifyLapScore(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/performanceManagement/modifyLapScore`, "post_json", params);
}